const training = [
  {
    title: "Software Training",
    url: "https://process.spoken-tutorial.org/index.php/Software-Training",
  },
  {
    title: "Contacts for Training",
    url: "https://process.spoken-tutorial.org/index.php/Software-Training#Contacts_For_Training",
  },
  {
    title: "Training Dashboard",
    url: "https://spoken-tutorial.org/accounts/login/?next=/software-training/",
  },
];
const about = [
  {
    title: "About Spoken Tutorial Us",
    url: "https://spoken-tutorial.org/about-us/",
  },
  { title: "Team", url: "https://spoken-tutorial.org/team/Creation-Team/" },
];
export { training, about };
