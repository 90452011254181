const viewAllGalleryList = [
  { url: "https://jrs.spoken-tutorial.org/media/gallery/4.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/IMG_5834.JPG" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/2.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/4.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/IMG_5834.JPG" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/3.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/4.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/IMG_5834.JPG" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/6.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/4.png" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/IMG_5834.JPG" },
  { url: "https://jrs.spoken-tutorial.org/media/gallery/6.png" },
];

export { viewAllGalleryList };
